<!--
 * @Autor: yzr
 * @Date: 2021-07-20 10:37:32
 * @LastEditors: yzr
 * @LastEditTime: 2021-09-09 10:59:52
 * @Description: 分利协议主体列表
-->
<script>
import TablePage from '@/found/components/table_page';
import request from '@/found/utils/request';

export default {
  name: 'modules',
  extends: TablePage,
  data() {
    return {
      // requestType: 'GET',
      // requestUrl: '/cps/v1/web/scanCodeRecordReport/findByConsumerConditions',
      requestType: 'POST',
      requestUrl: '/cps-mobile/consumer/scan/activity/consumerScanActivityReport/findConsumerScanReport', // 最新
      formConfig: {},
      modalConfig: {
      },
      formData: {},
      searchFormData: {},

    };
  },
  async created() {
    const createStartTime = this.formatTime(new Date().getTime(), 'Y-M-D 00:00:00');
    const createEndTime = this.formatTime(new Date().getTime(), 'Y-M-D 23:59:59');

    console.log(createStartTime);
    console.log(createEndTime);

    this.searchFormData = {
      ...this.searchFormData,
      createStartTime,
      createEndTime,
    };
    await this.getConfigList('cps-web-customer_record_flow-list');
    this.formData = {
      ...this.formData,
      createStartTime,
      createEndTime,
    };
  },
  mounted() {
  },
  methods: {
    // 设置规则
    formatTime(timestamp, format) {
      const formatArr = ['Y', 'M', 'D', 'h', 'm', 's'];
      let returnArr = [];
      const date = new Date(timestamp);
      const year = date.getFullYear();
      const month = date.getMonth() + 1;
      const day = date.getDate();
      const hour = date.getHours();
      const minute = date.getMinutes();
      const second = date.getSeconds();
      returnArr.push(year, month, day, hour, minute, second);
      returnArr = returnArr.map((a) => {
        let n = a;
        n = n.toString();
        return n[1] ? n : `0${n}`;
      });
      let aaa = format;
      console.log(aaa);
      for (const i in returnArr) {
        if (i) {
          // console.log(returnArr[i]);
          console.log(formatArr[i]);
          aaa = aaa.replace(formatArr[i], returnArr[i]);
          console.log(aaa);
        }
      }
      return aaa;
    },
  },
};
</script>
