/*
 * @Author: 冯杰
 * @Date: 2021-08-07 18:02:40
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-10-18 11:10:56
 * @FilePath: 关联协议列表
 */
import TablePage from '@/found/components/table_page';
import Modal from '@/found/components/modal';
import request from '@/found/utils/request';

export default {
  name: 'ProtocolList',
  extends: TablePage,
  props: {
    propsObj: Object,
  },
  data() {
    return {
      requestUrl: '/cps/v1/web/scanCodeAgreementReport/findByConditions',
      requestType: 'GET',
      params: {
        recordCode: this.propsObj.recordCode,
        participatorType: this.propsObj.participatorType,
        scanParticipatorCode: this.propsObj.scanParticipatorCode,
      },
    };
  },
  components: {
    Modal,
  },
  methods: {
    afterGetList() {
      if (this.tableData.length > 0) {
        // 扫码政策应后台需求写死 9.28 bug修改
        console.log('扫码政策', this.tableData);
        const newTableData = this.tableData.map((item) => ({
          ...item,
          policyNames: '扫码政策',
        }));
        this.tableData = newTableData;
      }
    },
    setColumn(col) {
      const item = col;
      if (item.field === 'checkTypeseq') {
        item.title = '序号';
        item.width = '50';
      }
      return item;
    },
    modalClick({ val, row }) {
      this.formConfig = {
        ...this.formConfig,
      };
      if (val.code === 'view') {
        // 跳转到分利协议模板
        this.$router.push({
          path: '/cps_web/treety/modules',
          query: {
            templateCode: row.templateCode,
          },
        });
        this.$emit('onClose');
      }
    },
  },
  created() {
    // 重写获取配置信息
    this.getConfigList('protocolList');
  },
};
