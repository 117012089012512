<!--
 * @Autor: yzr
 * @Date: 2021-07-20 10:37:32
 * @LastEditors: yzr
 * @LastEditTime: 2021-09-09 10:59:52
 * @Description: 分利协议主体列表
-->
<script>
import TablePage from '@/found/components/table_page';
import request from '@/found/utils/request';
import * as utils from '../../../utils/index';

export default {
  extends: TablePage,
  data() {
    return {
      requestType: 'GET',
      requestUrl: '/cps/v1/web/scanCodeRecordReport/findByTerminalConditions',
      params: {
      },
      formConfig: {},
      modalConfig: {
      },
    };
  },
  async created() {
    this.searchFormData.createTimeStart = utils.dateFormat('YYYY-mm-dd 00:00:00', new Date());
    this.searchFormData.createTimeEnd = utils.dateFormat('YYYY-mm-dd 23:59:59', new Date());
    await this.getConfigList('cps-web-terminal_record_flow-list');
  },
  mounted() {
  },
  methods: {
    // 判断是否有‘createTimeStart’字段
    hasCreateTimeStart() {
      const index = this.searchList.findIndex((item) => item.field === 'createTimeStart');
      if (index > -1) {
        return true;
      }
      return false;
    },
    // 判断是否有‘createTimeEnd’字段
    hasCreateTimeEnd() {
      const index = this.searchList.findIndex((item) => item.field === 'createTimeEnd');
      if (index > -1) {
        return true;
      }
      return false;
    },
    // 获取列表数据前置函数
    beforeGetList() {
      if (this.hasCreateTimeStart()) {
        this.formData.createTimeStart = this.searchFormData.createTimeStart || null;
      } else if (this.searchFormData.createTimeStart) {
        delete this.searchFormData.createTimeStart;
      }
      if (this.hasCreateTimeEnd()) {
        this.formData.createTimeEnd = this.searchFormData.createTimeEnd || null;
      } else if (this.searchFormData.createTimeEnd) {
        delete this.searchFormData.createTimeEnd;
      }
      return true;
    },
    /** @override 重写重置按钮 */
    beforeSearchEvent({ $event }) {
      if ($event.type === 'reset' && this.hasCreateTimeStart() && this.hasCreateTimeEnd()) {
        this.searchFormData.createTimeStart = utils.dateFormat('YYYY-mm-dd 00:00:00', new Date());
        this.formData.createTimeStart = this.searchFormData.createTimeStart;
        this.searchFormData.createTimeEnd = utils.dateFormat('YYYY-mm-dd 23:59:59', new Date());
        this.formData.createTimeEnd = this.searchFormData.createTimeEnd;
        return false;
      }
      return true;
    },
  },

};
</script>
