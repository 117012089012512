<script>
import TablePage from '@/found/components/table_page';
import request from '@/found/utils/request';

export default {
  name: 'modules',
  extends: TablePage,
  data() {
    return {
      requestType: 'GET',
      requestUrl: '/cps-mobile/h5/fleeingGoods/detail/findByConditions',
      params: {},
      formConfig: {},
      modalConfig: {},
      searchFormData: {},
      formData: {},
    };
  },
  async created() {
    await this.getConfigList('details_of_counterfeit_goods_list');
  },
  methods: {

  },
};
</script>
