/*
 * @Author: 冯杰
 * @Date: 2021-08-07 16:24:09
 * @LastEditTime: 2021-09-08 16:44:43
 * 扫码记录流水
 */
import TablePage from '@/found/components/table_page';
import Modal from '@/found/components/modal';
import request from '@/found/utils/request';
import Tree from '../package/table/components/tree.vue';
import Protocol from './protocol.js';

export default {
  name: 'TerminalList',
  extends: TablePage,
  data() {
    return {
      requestUrl: '/cps/v1/web/scanCodeRecordReport/findByConditions',
      requestType: 'GET',
    };
  },
  components: {
    Modal,
    Tree,
    Protocol,
  },
  methods: {
    beforeSetSearch(val) {
      const item = val;
      if (item.field === 'orgName') {
        item.typeName = 'Tree';
        item.type = 'customSearch';
      }
      return item;
    },

    modalClick({ val, row }) {
      this.formConfig = {
        ...this.formConfig,
        code: val.code,
      };
      if (val.code === 'export') {
        // 导出
      } else if (val.code === 'associated-order') {
        // 查看关联订单
      } else if (val.code === 'relevance-protocol') {
        // 查看关联协议
        this.modalConfig.title = '关联协议列表';
        this.formName = 'Protocol';
        this.propsObjInData = row;
        this.formConfig.buttonCode = 'relevance-protocol';
        this.modalConfig.showFooter = true;
        this.modalConfig.buttons = {
          sure: false,
          close: true,
        };
        this.openModal();
      }
    },
    setColumn(v) {
      const rowData = v;
      if (rowData.field === 'positionInfo') {
        rowData.formatter = ({ row }) => {
          const sheng = row.provinceName ? row.provinceName : '';
          const shi = row.cityName ? row.cityName : '';
          const qu = row.districtName ? row.districtName : '';
          const positionInfo = sheng + shi + qu;
          return positionInfo;
        };
      }

      return rowData;
    },
  },
  created() {
    // 重写获取配置信息
    this.getConfigList('recordFlowList');
  },
};
